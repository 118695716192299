@import "./variables.scss";
@import "./abstracts/mixins";

.topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.topbar .topbar-left {
  background: $white;
  float: left;
  text-align: center;
  height: 70px;
  position: relative;
  width: 220px;
  z-index: 1;
  .logo span {
    font-family: "appBold", sans-serif;
    color: $appSecondary;
  }
}

.topbar .topbar-left .logo {
  line-height: 105px;
}

.topbar .topbar-left .logo i {
  display: none;
}
.navbar-custom {
  background-image: url("../icons/nav_bar.svg");
  background-repeat: no-repeat;
  .country-name {
    position: relative;
    top: 10px;
    left: 10px;
    padding-right: 17px;
    color: #ffffff;
    font-size: 14px;
  }
}
.center {
  img {
    padding: 19px 0px 0px 10px;
  }
}
.logoTitle {
  display: flex;
}
.profileImg {
  display: flex;
  align-items: center;
  img {
    height: 50px;
    width: 50px;
  }
  .content {
    padding: 5px 10px;
    span {
      font-size: 12px;
    }
    .sub-title {
      font-size: 12px;
      color: #1d1d1d;
      margin-top: 10px;
    }
  }
  .title {
    font-size: 14px;
    margin: 0;
    line-height: 15px;
    font-weight: 700;
  }
}

/// side menu

.side-menu {
  display: flex;
  flex-direction: column;
  width: 220px;
  z-index: 10;
  background: $primary;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 70px;
  .sideMenuLine {
    width: 220px;
  }
  .sideMenuNav {
    flex: 1;
    overflow-y: auto;
  }
}

.side-menu .waves-effect .waves-ripple {
  background-color: rgba(51, 53, 71, 0.4);
}

.enlarged .side-menu {
  .sideMenuLine {
    width: 100px;
  }
}

.content-page {
  margin-left: 220px;
  overflow: hidden;
}

.content-page .content {
  padding: 60px 18px 0px 18px;
  height: 100vh;
  overflow: auto;
  // margin-bottom: 60px;
}

.button-menu-mobile {
  // border: none;
  // color: #525f80;
  // display: inline-block;
  // height: 70px;
  // width: 60px;
  // background-color: $topBar;
  // font-size: 24px;
}
#sidebar-menu > ul > li {
  position: relative;
}
#sidebar-menu > ul > li > a {
  color: $menuItems;
  display: block;
  padding: 12.5px 15px;
  font-size: 14px;
  position: relative;
  // font-family: "Sarabun", sans-serif;
  font-family: "appRegular", sans-serif !important;
  flex: 1;
}
// #sidebar-menu > ul > li > a.active {
//   padding: 12.5px 8px;
// }

#sidebar-menu > ul > li > a:hover {
  color: #d1dfec;
  text-decoration: none;
}
#sidebar-menu > ul > li > a:focus {
  color: $white;
  text-decoration: none;
}

#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
  margin-left: 7px;
}
#sidebar-menu > ul > li > a {
  img {
    width: 20px;
  }
}

#sidebar-menu {
  // padding-top: 10px;
  // height: calc(100vh - 156px);
  // overflow-y: auto;
}

#sidebar-menu .badge {
  margin-top: 7px;
}

#sidebar-menu li.active .menu-arrow i {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

#sidebar-menu ul li a i {
  vertical-align: middle;
  font-size: 14.7px;
  width: 20px;
  display: inline-block;
}
#sidebar-menu > ul > li > span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px;
  will-change: all;
  transition: 0.2s;
}
#sidebar-menu > ul > li > span.active {
  background-color: $white;
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 1px;
  // font-size: 16px;
  font-family: "appRegular", sans-serif;
  text-transform: uppercase;
  color: $menuItems;
}

.enlarged .slimScrollDiv {
  overflow: inherit !important;
}

.enlarged .slimScrollBar {
  visibility: hidden;
}

.enlarged #wrapper .navbar-custom {
  margin-left: 100px;
}

.enlarged #wrapper #sidebar-menu .menu-title,
.enlarged #wrapper #sidebar-menu .menu-arrow,
.enlarged #wrapper #sidebar-menu .badge {
  display: none !important;
}

.enlarged #wrapper #sidebar-menu .collapse.in {
  display: none !important;
}

.enlarged #wrapper #sidebar-menu .nav.collapse {
  height: inherit !important;
}

.enlarged #wrapper #sidebar-menu ul ul {
  margin-top: -2px;
  padding-bottom: 5px;
  padding-top: 5px;
  z-index: 9999;
  background-color: #333547;
}
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > span {
  bottom: 0;
  width: 50px;
  height: 5px;
  top: auto;
  border-radius: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a {
  padding: 20px 25px;
  min-height: 65px;
  text-align: center;
}

.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a:hover {
  color: #d1dfec !important;
  // background-color: $white;
}
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a:focus {
  color: #ffffff !important;
  // background-color: $white;
}
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a:active {
  color: #ffffff !important;
  background-color: $white;
}

.enlarged #wrapper .left.side-menu {
  width: 100px;
  z-index: 5;
}
.testflag {
  background: none !important;
  border: none !important;
  color: black !important;
}

.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a i {
  font-size: 18px;
  margin-left: 5px;
  margin-right: 20px !important;
}

.enlarged #wrapper .left.side-menu #sidebar-menu ul > li {
  position: relative;
  white-space: nowrap;
  margin: 8px 0;
}

// .enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover > a span {
//   // position: relative;
//   // width: 260px;
//   // color: #626ed4 !important;
//   // background-color: #d1dfec;
// }

.enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
  height: auto !important;
}

.enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover > ul a {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
}

.enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover > ul a:hover {
  color: #ffffff;
}

.enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover a span {
  display: none;
}

.enlarged #wrapper .left.side-menu #sidebar-menu ul ul li:hover > ul {
  display: block;
  left: 190px;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}

.enlarged #wrapper .left.side-menu #sidebar-menu ul ul li > a span.float-right {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  position: absolute;
  right: 20px;
  top: 12px;
  transform: rotate(270deg);
}

.enlarged #wrapper .left.side-menu #sidebar-menu ul ul li.active a {
  color: #ffffff;
}

.enlarged #wrapper .left.side-menu #sidebar-menu ul > li > a span {
  display: none;
  padding-left: 10px;
}

.enlarged #wrapper .left.side-menu .user-details {
  display: none;
}

.enlarged #wrapper .content-page {
  margin-left: 100px;
}

.enlarged #wrapper .footer {
  left: 70px;
}

.enlarged #wrapper .topbar .topbar-left {
  width: 100px !important;
}

.enlarged #wrapper .topbar .topbar-left .logo span {
  display: none;
  opacity: 0;
}

.enlarged #wrapper .topbar .topbar-left .logo i {
  display: block;
  line-height: 79px;
  // color: #626ed4 !important;
}

.enlarged #wrapper #sidebar-menu > ul > li:hover > a.open :after {
  display: none;
}

.enlarged #wrapper #sidebar-menu > ul > li:hover > a.active :after {
  display: none;
}
.enlarged #wrapper #sidebar-menu > ul > li img {
  width: 20px;
}

//scroll

// ::-webkit-scrollbar {
//   width: 0.3em;

// }
// ::-webkit-scrollbar-button {
//   background: #ffffff;
// }
// ::-webkit-scrollbar-track-piece {
//   background: $appSecondary;
// }

.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-input {
  input {
    border: 1px solid rgb(31, 68, 90);
    background-color: #ffffff;
    outline: none;
    width: 260px;
    //height: 40px;
    height: 38px;
    padding-left: 15px;
    border-radius: 5px;
    font-size: 14px;
  }
  .search-i {
    position: relative;
    right: 30px;
    filter: invert(100%);
  }
  .layout {
    position: relative;
    right: 46px;
    height: 37px;
    bottom: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  i {
    position: relative;
    //right: 45px;
    right: 38px;
    bottom: 13px;
  }
}

.profileSettings {
  margin-top: 5px;
}
.profileSettings .topBar-title {
  display: flex;
  flex-direction: column;
  font-size: small;
  color: black;
  padding: 0px 5px 0px 5px;
}
.user-full-name {
  margin: 2px 0px 1px 0px;
  cursor: default;
}
.topBar-title > p.role-title {
  line-height: 1 !important;
  margin-bottom: 1px !important;
  cursor: default;
}
.select-language {
  margin-top: 10px;
}
.role-title.multi {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@include respond(large-desktop) {
  .side-menu {
    width: 260px;
    .sideMenuLine {
      width: 260px;
    }
  }
  .topbar .topbar-left {
    width: 260px;
  }
  #sidebar-menu > ul > li > a {
    font-size: 1rem;
  }
  .content-page {
    margin-left: 260px;
  }
}
@include respond(big-desktop) {
  .side-menu {
    width: 240px;
    .sideMenuLine {
      width: 240px;
    }
  }
  .topbar .topbar-left {
    width: 240px;
  }
  #sidebar-menu > ul > li > a {
    font-size: 1rem;
  }
  .content-page {
    margin-left: 240px;
  }
}
@include respond(medium-desktop) {
  .side-menu {
    width: 220px;
    .sideMenuLine {
      width: 220px;
    }
  }
  .topbar .topbar-left {
    width: 220px;
  }
  #sidebar-menu > ul > li > a {
    font-size: 1rem;
  }
  .content-page {
    margin-left: 220px;
  }
}
@include respond(normal-desktop) {
  .side-menu {
    width: 210px;
    .sideMenuLine {
      width: 210px;
    }
  }
  .topbar .topbar-left {
    width: 210px;
  }
  #sidebar-menu > ul > li > a {
    font-size: 1rem;
  }
  .content-page {
    margin-left: 210px;
  }
}
@include respond(small-desktop) {
  .side-menu {
    width: 200px;
    .sideMenuLine {
      width: 200px;
    }
  }
  .topbar .topbar-left {
    width: 200px;
  }
  #sidebar-menu > ul > li > a {
    font-size: 1rem;
  }
  .content-page {
    margin-left: 200px;
  }
}
