// @import url("https://fonts.googleapis.com/css?family=Roboto:400,500|Sarabun:400,600,700");

@import "./icons.scss";
@import "./variables.scss";
@import "./abstracts/mixins";

@font-face {
  font-family: "appRegular", sans-serif;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeue-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "appBold", sans-serif;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/HelveticaNeue-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "appItalic", sans-serif;
  font-style: bold;
  font-weight: bold;
  src: url("../fonts/HelveticaNeue-Italic.ttf") format("truetype");
}

body {
  background: #f8f8fa;
  background-repeat: repeat;
  font-family: "appRegular", sans-serif;
  color: #5b626b;
}

html {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
  //define what 1rem is
  font-size: 87.5%; //14px
  //1rem= 14px; 14px/16px*100=87.5%

  @include respond(large-desktop) {
    font-size: 112.5%; //1rem=18px
  }
  @include respond(big-desktop) {
    font-size: 100%; // 1rem=16px
  }
  @include respond(medium-desktop) {
    font-size: 93.75%; // 1rem=15px
  }
  @include respond(normal-desktop) {
    font-size: 87.5%; // 1rem=14px
  }
  @include respond(small-desktop) {
    font-size: 81.25%; // 1rem=13px
  }
}

h1,
h2,
h3,
h5,
h4,
h6 {
  margin: 10px 0;
  font-family: "appBold", sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

p {
  line-height: 1.7;
}

svg {
  max-width: 100%;
}
span {
  font-family: "appRegular", sans-serif;
}

a {
  color: $appSecondary;
}

a:hover {
  outline: 0;
  text-decoration: none;
}

a:active {
  outline: 0;
  text-decoration: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

b,
strong {
  font-weight: 500;
}

.container-alt {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

#wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #626ed4;
}

.MuiTypography-body1 {
  font-family: "appRegular", sans-serif !important;
}

/* ==============
  Bootstrap-custom
===================*/
.dropdown-menu {
  padding: 4px 0;
  font-size: 14px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
  box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
  background-color: #ffffff;
  border-color: #e9ecef;
  margin: 0;
}

.dropdown-item {
  background-color: #ffffff !important;
  padding: 0.55rem 1.5rem;
}

.dropdown-item:active,
.dropdown-item:hover {
  background-color: #f8f9fa;
  color: #2a3142;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f9fa;
  color: #2a3142;
}

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #9ca8b3;
  content: "\f105" !important;
  font-family: "appRegular", sans-serif;
}

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid #e9ecef;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid #e9ecef;
  text-align: right;
}

.bg-primary {
  background-color: #626ed4 !important;
}

.bg-success {
  background-color: #02a499 !important;
}

.bg-info {
  background-color: #38a4f8 !important;
}

.bg-warning {
  background-color: #f8b425 !important;
}

.bg-danger {
  background-color: #ec4561 !important;
}

.bg-muted {
  background-color: #9ca8b3 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #ec4561 !important;
}

.text-muted {
  color: #9ca8b3 !important;
}

.text-primary {
  color: #626ed4 !important;
}

.text-warning {
  color: #f8b425 !important;
}

.text-success {
  color: #02a499 !important;
}

.text-info {
  color: #38a4f8 !important;
}

.text-dark {
  color: #2a3142 !important;
}

.badge {
  font-weight: 500;
}

.badge-primary {
  background-color: #626ed4;
}

.badge-success {
  background-color: #02a499;
}

.badge-info {
  background-color: #38a4f8;
}

.badge-warning {
  background-color: #f8b425;
  color: #ffffff;
}

.badge-danger {
  background-color: #ec4561;
}

.badge-dark {
  background-color: #2a3142;
}

dt {
  font-weight: 500;
}

.spinner-border,
.spinner-grow {
  margin-right: 10px;
  margin-top: 10px;
}

.custom-control-input:checked ~ .custom-control-label:before {
  border-color: #626ed4;
  background-color: #626ed4;
}

/* ==============
  Waves Effect
===================*/

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  background: radial-gradient(
    rgba(0, 0, 0, 0.2) 0,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform: scale(0) translate(0, 0);
  transform: scale(0) translate(0, 0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
  background: radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
}

.waves-effect.waves-classic .waves-ripple {
  background: rgba(0, 0, 0, 0.2);
}

.waves-effect.waves-classic.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}

.waves-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.waves-button,
.waves-circle {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.waves-button,
.waves-button:hover,
.waves-button:visited,
.waves-button-input {
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  outline: none;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1em;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  z-index: 1;
}

.waves-button {
  padding: 0.85em 1.1em;
  border-radius: 0.2em;
}

.waves-button-input {
  margin: 0;
  padding: 0.85em 1.1em;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}

.waves-input-wrapper.waves-button {
  padding: 0;
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
}

.waves-float {
  -webkit-mask-image: none;
  -webkit-box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.waves-float:active {
  -webkit-box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
}

.waves-block {
  display: block;
}

/* Metis Menu css */
.metismenu {
  padding: 0;
}

.metismenu ul {
  padding: 0;
}

.metismenu ul li {
  list-style: none;
}

.navbar-custom {
  background-color: $topBar;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px 0 0;
  margin-left: 220px;
  min-height: 50px;
}

.navbar-custom .nav-link {
  padding: 0 4px;
}
.profileSettings {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.profileToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  p {
    line-height: 0;
  }
}

.navbar-custom .navbar-right .dropdown-toggle:after {
  content: initial;
}

.logo {
  color: #2a3142 !important;
  font-size: 16px;
  font-weight: 500;
}

/* Notification */
.notification-item-list {
  max-height: 230px;
}

.notification-list .noti-icon {
  font-size: 24px;
  vertical-align: middle;
  color: #525f80;
}

.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 16px;
  right: 12px;
}

.notification-list .notify-item {
  padding: 10px 20px;
}

.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
}

.notification-list .notify-item .notify-icon i {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  line-height: 32px;
  margin-top: 2px;
  color: #ffffff;
}

.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-family: "appRegular", sans-serif;
}

.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 12px;
  font-weight: normal;
  font-family: "appRegular", sans-serif;
}

.notification-list .language-switch a img {
  float: right;
}

.notification-list .profile-dropdown .notify-item {
  padding: 4px 20px;
}

.notification-list .nav-link {
  padding: 0 15px;
  line-height: 70px;
  color: #525f80;
}

.notification-list.show .nav-link {
  background-color: rgba(42, 49, 66, 0.05);
}

.profile-dropdown {
  width: 170px;
}

.profile-dropdown i {
  font-size: 17px;
  vertical-align: middle;
  margin-right: 5px;
  color: #6c757d;
}

.profile-dropdown span {
  margin-top: 3px;
}

.arrow-none:after {
  border: none;
  margin: 0;
  display: none;
}

.dropdown-menu-lg {
  width: 300px;
}
.withIcon {
  position: relative;
}
.withIcon i {
  position: absolute;
  top: 12px;
  right: 18px;
  font-size: 12px;
  cursor: pointer;
}

.nav-user img {
  margin-top: -3px;
}

.page-title-box {
  padding: 20px 0px;
}

.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 30px;
  font-weight: 700;
}

.page-title-box .breadcrumb {
  padding: 4px 0;
  background-color: transparent;
  margin-bottom: 0;
}

.page-title-box .breadcrumb a {
  color: #2a3142;
}

.page-title-box .breadcrumb a:hover {
  color: rgba(42, 49, 66, 0.9);
}

.page-title-box .breadcrumb .active {
  color: rgba(42, 49, 66, 0.7);
}

/* ==============
  Buttons
===================*/
.btn {
  border-radius: 3px;
  font-size: 14px;
  @include respond(large-desktop) {
    font-size: 18px;
  }
  @include respond(big-desktop) {
    font-size: 16px;
  }
  @include respond(normal-desktop) {
    font-size: 14px;
  }
  @include respond(small-desktop) {
    font-size: 12px;
  }
}

button:focus {
  outline: none;
}

.btn-sm {
  font-size: 11.66667px;
}

.btn-lg {
  font-size: 16.8px;
}

/* ==============
  Card
===================*/
.card {
  border: none;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
  box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-gap: 30px;
    column-gap: 30px;
  }
}

.card-columns .card {
  margin-bottom: 30px;
}

/* ==============
  Form-elements
===================*/
label {
  font-family: "appRegular", sans-serif !important;
}

.form-control {
  font-size: 14px;
  border-radius: 0.4rem !important;
}

.form-control:focus {
  border-color: #626ed4;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.invalid {
  border-color: $required !important;
}
.error {
  color: $required;
  font-family: "appRegular", sans-serif;
  font-size: 11px;
  @include respond(large-desktop) {
    font-size: 0.666rem;
  }
  @include respond(big-desktop) {
    font-size: 0.75rem;
  }
  @include respond(normal-desktop) {
    font-size: 0.78rem;
  }
  @include respond(small-desktop) {
    font-size: 0.769rem;
  }
}

.invalid:focus {
  box-shadow: 0 0 0 0.15rem #ec456140;
}

.form-control-sm {
  font-size: 0.875rem;
}

.custom-select-sm {
  font-size: 75%;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #626ed4;
}

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #626ed4;
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #626ed4;
}

.close-popup-icon {
  width: 30px;
  height: 30px;
}
.popup-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  .popup-title {
    text-align: center;
    font-weight: 600;
    p {
      color: #000000;
      font-size: 18px;
      label {
        font-size: 14px;
      }
    }
  }
  strong {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }

  .soh-dropdown {
    width: 20rem;
    padding-bottom: 1rem;
  }
}
.popup-btn {
  border-radius: 50px !important;
  text-transform: none !important;
  min-width: 120px !important;
}

.popup-btn.close-btn {
  background: #ffffff !important;
  border: 1px solid #89d329;
  color: #121212;
  box-shadow: 0px 3px 6px #c7c7c729 !important;
  margin-right: 20px;
}
.popup-btn.filter-scan {
  background: #7eb343 !important;
  color: #ffffff;
}

.admin-popup-btn {
  text-transform: none !important;
  min-width: 150px !important;
}

.admin-popup-btn.close-btn {
  background: #ffffff !important;
  border: 1px solid #89d329;
  color: #121212;
  box-shadow: 0px 3px 6px #c7c7c729 !important;
  margin-right: 20px;
}
.admin-popup-btn.filter-scan {
  background: #7eb343 !important;
  color: #ffffff;
}
.admin-popup-btn.delete {
  background: #fc2b0a !important;
  color: #ffffff;
}
.card-main {
  height: 82vh;
  @include respond(large-desktop) {
    height: 95vh;
  }
  @include respond(big-desktop) {
    height: 88vh;
  }
  @include respond(medium-desktop) {
    height: 84vh;
  }
  @include respond(normal-desktop) {
    height: 82vh;
  }
  @include respond(small-desktop) {
    height: 80vh;
  }
}

.reset-btn {
  min-width: 125px;
}
.applybtn {
  background-color: #7eb343;
  min-width: 125px;
  color: #ffffff;
}
.user-filter-date-picker {
  width: 150px;
}

.no-records {
  text-align: center !important;
}

.cus-btn {
  color: #ffffff;
  background: url("../../assets/icons/arrow_with_bg.svg") no-repeat right;
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  span {
    position: absolute;
    right: 30px;
    line-height: 1;
  }
  .layout {
    width: 60%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
.cus-btn:disabled,
cus-btn-scanlog-filter[disabled] {
  background-color: #96a8ae !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}

.Toastify__toast-container {
  width: 320px;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3276b1;
}
.Toastify__toast--success {
  background: #51a351;
}
.Toastify__toast--warning {
  background: #f89406;
}
.Toastify__toast--error {
  background: #bd362f;
}

.popover {
  min-width: 500px;
  .popover-header {
    background-color: #ffffff;
    text-align: center;
  }
  .dev-popupover {
    .title {
      background-color: #f7f7f7;
      padding: 0.5rem;
    }
    .desc {
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $appSecondary;
  border-radius: 10px;
  background-color: #e5e4e2;
  outline: 1px solid slategrey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $appSecondary;
}

@include respond(large-desktop) {
  .navbar-custom {
    margin-left: 260px;
  }
}
@include respond(big-desktop) {
  .navbar-custom {
    margin-left: 240px;
  }
}
@include respond(medium-desktop) {
  .navbar-custom {
    margin-left: 220px;
  }
}
@include respond(normal-desktop) {
  .navbar-custom {
    margin-left: 210px;
  }
}
@include respond(small-desktop) {
  .navbar-custom {
    margin-left: 200px;
  }
}

.toaster-custom {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.readonly {
  opacity: 0.5;
  pointer-events: none;
}

.disabled-class {
  pointer-events: none;
  background-color: #ffffff !important;
  opacity: 0.7 !important;
}

.applywordWrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
