// Media query manager

/*
1280px :Small desktop
1366px : Normal desktop
1680px : Medium desktop
1920px : Big desktop
2880px :Large desktop

*/

/*
breakpoint arguments choices: 
- small-desktop
- normal-desktop
- medium-desktop
- big-desktop
- large-desktop

1em =16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint==small-desktop {
    @media (max-width: 80em) {
      @content;
    }
    //1280px
  }
  @if $breakpoint==normal-desktop {
    @media (max-width: 85.375em) {
      @content;
    }
    //1366px
  }
  @if $breakpoint==medium-desktop {
    @media (max-width: 105em) {
      @content;
    }
    //1680px
  }
  @if $breakpoint==big-desktop {
    @media (max-width: 120em) {
      @content;
    }
    //1920px
  }
  @if $breakpoint==large-desktop {
    @media (max-width: 180em) {
      @content;
    }
    //2880px
  }
}
